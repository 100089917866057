import { AppConfig } from '@unifly-aero/web-common/src/config/AppConfig';
import SentryAppConfiguration from '@unifly-aero/web-sentry/src/config/AppConfiguration';
import CommonAppConfiguration from '@unifly-aero/proj-common/src/config/AppConfiguration';
import merge from 'lodash.merge';

const AppConfiguration: AppConfig = merge<AppConfig, DeepPartial<AppConfig>, DeepPartial<AppConfig>>(CommonAppConfiguration, SentryAppConfiguration, {
    i18n: {
        locales: ['de-DE', 'en']
    },
    clientId: 'DFSUTMSentry',
    clientSecret: '',
    showClientIdOnOperatorCreation: false
});

export default AppConfiguration;
